<template>
  <div class="subsidyListDetail">
    <div class="back">
      <el-button type="text" @click="back"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="title">
      <h3>达州市达川区农机购置补贴申请表</h3>
    </div>
    <div class="time">
      申报日期:{{ detailsList.createTime }}
    </div>
    <div class="table">
      <table style="border: solid 1px #ccc">
        <tr>
          <td rowspan="5" colspan="2">基础信息</td>
          <td>姓名/单位</td>
          <td>{{detailsList.applyUser}}</td>
          <td>联系电话</td>
          <td>{{detailsList.phone}}</td>
        </tr>
        <tr>
          <td>身份证号/机构代码</td>
          <td  colspan="4">{{detailsList.idCard}}</td>
        </tr>
        <tr>
          <td>地址</td>
          <td  colspan="4">{{detailsList.address}}</td>
        </tr>
        <tr>
          <td  rowspan="2">账户</td>
          <td>开户行</td>
          <td colspan="2">账户</td>
        </tr>
        <tr>
          <td>{{detailsList.bankName}}</td>
          <td colspan="2">{{detailsList.bankCard}}</td>
        </tr>
        <tr>
          <td colspan="2" rowspan="3">农机购置项目</td>
          <td>农机类型</td>
          <td>{{detailsList.agriculturalMachineryType}}</td>
          <td>农机生产商</td>
          <td>{{detailsList.agriculturalMachineryManufacturer}}</td>

        </tr>
        <tr>
          <td>农机价格</td>
          <td>{{detailsList.agriculturalMachineryPrice}}</td>
          <td>农机经销商</td>
          <td>{{detailsList.agriculturalMachineryDealer}}</td>
        </tr>
        <tr>
          <td>农机型号</td>
          <td>{{detailsList.agriculturalMachineryModel}}</td>
          <td>发动机编号(如有)</td>
          <td>{{detailsList.engineNumber}}</td>
        </tr>
    </table>
    <div class="introduce2">
      <div class="left">
        <div class="title">
            <span>  本人承诺购买行为、发票、购机价格等信息真实有效，按相关规定申办补贴。</span>
        </div>

       <div class="acceptor">
          <div class="name">承诺人(盖章签字):</div>
          <div class="img">
            <el-image  style="width: 100px; height: 100px" :src="baseUrl + 'admin/file/get?ossFilePath=' + detailsList.sign">
            </el-image>
          </div>
       </div>
       <div class="time">
          2024年01月01日
       </div>
      </div>
      <div class="right">
        <div class="title">
        农服中心签署意见并加盖公章
      </div>
      <div class="auditResult">
         <el-input
          type="textarea"
          placeholder="请输入审批意见"
          v-model="textarea2">
        </el-input>

      </div>
     <div class="acceptor">
        <div class="name">负责人签字:</div>
        <div class="img">
          <!-- <img src="@/assets/bank_logo.png" alt=""> -->
          <el-image style="width: 100px; height: 100px" :src="baseUrl + 'admin/file/get?ossFilePath=' + detailsList.sign">
          </el-image>
        </div>
     </div>
     <div class="time">
        2024年01月01日
     </div>
      </div>
    </div>
    <!-- 法定代表人身份证照片： -->
    <div class="photoList">
      <div class="label">法定代表人身份证照片：</div>
      <div class="imgList">
        <el-image style="width: 150px; height: 90px ;padding-right: 20px" :src="baseUrl + 'admin/file/get?ossFilePath=' +detailsList.legalIdcardPicFront ">
          <div slot="error" class="image-slot">
            <img  src="@/assets/icon/imgError.png" />
          </div>
        </el-image>
        <el-image style="width: 150px; height: 90px" :src="baseUrl + 'admin/file/get?ossFilePath=' + detailsList.legalIdcardPicBack">
          <div slot="error" class="image-slot">
            <img src="@/assets/icon/imgError.png" />
          </div>
        </el-image>
      </div>
    </div>
    <!-- 营业执照 -->
    <div class="photoList" v-if="detailsList.businessLicense">
      <div class="label">营业执照</div>
      <div class="imgList">
        <el-image style="width: 150px; height: 90px" :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl2">
          <div slot="error" class="image-slot">
            <img src="@/assets/icon/imgError.png" />
          </div>
        </el-image>

      </div>
    </div>
    <!-- 农机购置发票 -->
     <div class="photoList2">
      <div class="label">农机购置发票</div>
      <div class="imgList">
        <el-image style="width: 150px; height: 90px"  v-for="(item,index) in JSON.parse(detailsList.agriculturalMachineryInvoiceUrl)" :key="index" :src="baseUrl + 'admin/file/get?ossFilePath='+item.imgUrl">
          <div slot="error" class="image-slot">
            <img src="@/assets/icon/imgError.png" />
          </div>
        </el-image>
      </div>
     </div>
     <div class="photoList2">
      <div class="label">其他证明材料</div>
      <div class="imgList">
        <el-image style="width: 150px; height: 90px"  v-for="(item,index) in JSON.parse(detailsList.otherFiles)" :key="index" :src="baseUrl + 'admin/file/get?ossFilePath='+item.imgUrl">
          <div slot="error" class="image-slot">
            <img src="@/assets/icon/imgError.png" />
          </div>
        </el-image>
      </div>
     </div>

     <div class="photoList2">
      <div class="label">所购机具试验鉴定证书</div>
      <div class="imgList">
        <el-image style="width: 150px; height: 90px"  v-for="(item,index) in JSON.parse(detailsList.agriculturalMachineryCertificateUrl)" :key="index" :src="baseUrl + 'admin/file/get?ossFilePath='+item.imgUrl">
          <div slot="error" class="image-slot">
            <img src="@/assets/icon/imgError.png" />
          </div>
        </el-image>
      </div>
     </div>
    </div>
    <div class="BtnClass">

    </div>
  </div>
</template>

<script>
import { selectDeclareById} from "@/api/subsidy";
export default {
  data(){
    return {
      baseUrl: window.globalUrl.BASE_API,
      textarea2:'',
      logoUrl2:'',
      detailsList:{}
    }
  },

  mounted(){
      this.selectDeclareById()
    },
  methods: {
    // 查看详情
   async selectDeclareById(){
        console.log(this.$route.query.declareId)
        let params={
          declareId:this.$route.query.declareId
        }
        let res =await selectDeclareById(params)
        if(res.code==0){
           this.detailsList=res.data
        }

    },
    back(){
       this.$router.go(-1); // 返回上一级路由
     },
  }
}
</script>

<style lang="less" scoped>
.subsidyListDetail{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .back{
    font-size: 22px;
  }
  .title{
    display: flex;
    justify-content: center;
  }
  .time{
    text-align: right;
    margin-bottom: 20px;
  }
  table{
    width: 100%;
    border-collapse: collapse; /* 合并线段 */
    text-align: center;

    th,
    td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
      text-align: center;
    }
  }
  .introduce2{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    height: 300px;
    width:100%;
    border: solid 1px black;
    border-top:none;
    display: flex;
    .left{
      width: 50%;
      display: flex;
      padding-top:20px;
      align-items: center;
      flex-direction: column;
      border-right: solid 1px black;
      .title{
        width:90%;
        height:60%;
      }
      .acceptor{
        width:90%;
        height:30%;
        display: flex;
        .img{
          margin-left: 20px;;
        }
      }
      .time{
       width:95%;
      }
    }
    .right{
      width: 50%;
      display: flex;
      padding-top:20px;
      align-items: center;
      flex-direction: column;
      border-right: solid 1px black;
      .title{
        width:90%;
        height:10%;
      }
      .auditResult{
        width:90%;
        height:50%;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .acceptor{
        width:90%;
        height:30%;
        display: flex;
        .img{
          margin-left: 20px;
        }
      }
      .time{
       width:95%;
      }
    }
  }
  .photoList{
    width: 100%;
    height: auto;
    display: flex;
    .label{
      width:15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .photoList2{
    width: 100%;
    height: auto;
    display: flex;
    .label{
      width:15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
